import React, { useCallback, useState, useContext } from "react";
import * as headerStyles from "./header.module.css";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";

import { AiOutlineShoppingCart, AiOutlineHeart, AiOutlineClose } from 'react-icons/ai';
import { navigate } from "@reach/router"
import clsx from "clsx";
import { useShopifyTags } from "../helperHooks";
import { StoreContext } from '../../provider/StoreProvider';
import { WishlistContext } from '../../provider/WishlistProvider';
import { getPrice, getUniqueCollections } from '../../utils/shopifyUtils';
import { withStyles } from "@material-ui/core";

const imageQuery = graphql`{
  logo: file(relativePath: {eq: "Love_Shack_Vintage_Logos_title_header.png"}) {
    childImageSharp {
      gatsbyImageData(height: 45, layout: FIXED)
    }
  }
  logoSmall: file(relativePath: {eq: "Love_Shack_Vintage_Logos_title_header.png"}) {
    childImageSharp {
      gatsbyImageData(height: 30, layout: FIXED)
    }
  }
  allShopifyCollection(filter: {handle: {eq: "vintage-glassware-candles"}}) {
    nodes {
      title
      handle
    }
  }
  rentals: allShopifyCollection(filter: {products: {elemMatch: {tags: {eq: "rent"}}}}) {
    nodes {
      title
      handle
    }
  }
}
`;

const ListLink = (props) => (
  <Link style={{ display: "inline-block", marginRight: "1rem" }} to={props.to} className={props.listClassName} activeClassName={props.activeClassName} partiallyActive>
    <li className={props.className} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        {props.children}
    </li>
  </Link>
);

export default function Header(props) {
  const image = useStaticQuery(imageQuery);
  const isLargeScreen = useMediaQuery("(min-width:985px)");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [productsMenuRef, setProductsMenuRef] = useState(null);
  const [productsHoverTimeout, setProductsHoverTimeout] = useState(null);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [servicesHoverTimeout, setServicesHoverTimeout] = useState(null);
  const [rentalsMenuOpen, setRentalsMenuOpen] = useState(false);
  const [rentalsHoverTimeout, setRentalsHoverTimeout] = useState(null);

  const { rentals } = image;

  const {
    checkout,
    isOpen,
    setIsOpen
  } = useContext(StoreContext);

  const {
    wishlist,
    isOpen: wishlistIsOpen,
    setIsOpen: setWishlistIsOpen
  } = useContext(WishlistContext);

  const shopifyCollections = getUniqueCollections(image.allShopifyCollection.nodes ? image.allShopifyCollection.nodes.map(collection => collection) : []);
  const rentalCollections = getUniqueCollections(rentals.nodes ? rentals.nodes.map(collection => collection) : []);

  const navigateToUrl = useCallback((url) => {
    navigate(url);
    setProductsMenuRef(null);
  }, []);

  return (
    <div style={{ width: "100%", height: "120px", float: "left", position: "fixed", zIndex: 3 }}>
      <div className={headerStyles.shopContainer}>
        <div className={headerStyles.shopSubContainer}>
          <button className={headerStyles.shopHeaderItem} onClick={() => { setIsOpen(true) }}>
            <AiOutlineShoppingCart />
            {checkout.lineItems && checkout.lineItems.length > 0 && <span className={headerStyles.cartNumber}>{checkout.lineItems.length}</span>}
            <div
              className={headerStyles.shopHeaderItemText}
              style={{
                marginLeft: '6px',
                marginRight: '10px'
              }}
            >CART</div>
          </button>
          <div className={headerStyles.divider}>|</div>
          <button className={headerStyles.shopHeaderItem} onClick={() => { setWishlistIsOpen(true) }}>
            <div
              className={headerStyles.shopHeaderItemText}
              style={{
                marginLeft: '10px',
                marginRight: '6px'
              }}
            >WISHLIST</div><AiOutlineHeart style={{ marginTop: '1px' }} />{wishlist.lineItems && wishlist.lineItems.length > 0 && <span className={headerStyles.wishlistNumber}>{wishlist.lineItems.length}</span>}
          </button>
        </div>
      </div>
      <header
        style={{
          width: "100%",
          height: "80px",
          display: "flex",
          backgroundColor: "#FFFFFF",
          lineHeight: "50px",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {isLargeScreen && (
          <ul style={{ listStyle: `none`, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ListLink to="/about/" className={headerStyles.link} activeClassName={headerStyles.active} listClassName={headerStyles.linkContainer}>
              ABOUT US
            </ListLink>
            <ListLink
              to="/services/"
              className={headerStyles.link}
              activeClassName={headerStyles.active}
              listClassName={headerStyles.linkContainer}
              onMouseEnter={(event) => { setServicesMenuOpen(event.currentTarget); }}
              onMouseLeave={() => {
                setServicesHoverTimeout(
                  setTimeout(() => {
                    setServicesMenuOpen(null);
                  }, 200)
                );
              }}
            >
              SERVICES
            </ListLink>
            <Popper open={Boolean(servicesMenuOpen)} anchorEl={servicesMenuOpen} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={headerStyles.paper}>
                    <ClickAwayListener onClickAway={() => { setServicesMenuOpen(null); }}>
                      <MenuList
                        autoFocusItem={Boolean(servicesMenuOpen)}
                        id="menu-list-grow-services"
                        onMouseOver={() => {
                          if (servicesHoverTimeout) {
                            clearTimeout(servicesHoverTimeout);
                            setServicesHoverTimeout(null);
                          }
                        }}
                        onMouseLeave={() => { setServicesMenuOpen(null); }}
                        className={headerStyles.menu}
                      >
                        <MenuItem onClick={() => { navigateToUrl(`/services/portfolio`) }} className={headerStyles.menuListItem}>PORTFOLIO</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <ListLink 
              to="/rentals/" 
              className={headerStyles.link} 
              activeClassName={headerStyles.active} 
              listClassName={headerStyles.linkContainer}
              onMouseEnter={(event) => { setRentalsMenuOpen(event.currentTarget); }}
              onMouseLeave={() => {
                setRentalsHoverTimeout(
                  setTimeout(() => {
                    setRentalsMenuOpen(null);
                  }, 200)
                );
              }}
            >
              RENTALS
            </ListLink>
            <Popper open={Boolean(rentalsMenuOpen)} anchorEl={rentalsMenuOpen} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={headerStyles.paper}>
                    <ClickAwayListener onClickAway={() => { setRentalsMenuOpen(null); }}>
                      <MenuList
                        autoFocusItem={Boolean(rentalsMenuOpen)}
                        id="menu-list-grow-rentals"
                        onMouseOver={() => {
                          if (rentalsHoverTimeout) {
                            clearTimeout(rentalsHoverTimeout);
                            setRentalsHoverTimeout(null);
                          }
                        }}
                        onMouseLeave={() => { setRentalsMenuOpen(null); }}
                        className={headerStyles.menu}
                      >
                        {
                          rentalCollections.map(collection => (
                            <MenuItem onClick={() => { navigateToUrl(`/rentals/items#${collection.handle}`) }} className={headerStyles.menuListItem}>{collection.title}</MenuItem>
                          ))
                        }
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Link
              to="/"
              style={{ textShadow: `none`, backgroundImage: `none`, display: "inline-block", alignItems: "center", height: "auto" }}
              className={clsx(headerStyles.link, headerStyles.logo)}
            >
              <GatsbyImage
                image={image.logo.childImageSharp.gatsbyImageData}
                style={{ overflow: "visible" }}
                imgStyle={{ objectFit: "contain" }} />
            </Link>
            <ListLink
              to="/shop/"
              className={headerStyles.link}
              activeClassName={headerStyles.active}
              listClassName={headerStyles.linkContainer}
              onMouseEnter={(event) => { setProductsMenuRef(event.currentTarget); }}
              onMouseLeave={() => {
                setProductsHoverTimeout(
                  setTimeout(() => {
                    setProductsMenuRef(null);
                  }, 200)
                );
              }}
            >
              SHOP
            </ListLink>
            <Popper open={Boolean(productsMenuRef)} anchorEl={productsMenuRef} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={headerStyles.paper}>
                    <ClickAwayListener onClickAway={() => { setProductsMenuRef(null); }}>
                      <MenuList
                        autoFocusItem={Boolean(productsMenuRef)}
                        id="menu-list-grow"
                        onMouseOver={() => {
                          if (productsHoverTimeout) {
                            clearTimeout(productsHoverTimeout);
                            setProductsHoverTimeout(null);
                          }
                        }}
                        onMouseLeave={() => { setProductsMenuRef(null); }}
                        className={headerStyles.menu}
                      >
                        {
                          shopifyCollections.map(collection => (
                            <MenuItem onClick={() => { navigateToUrl(`/shop/products#${collection.handle}`) }} className={headerStyles.menuListItem}>{collection.title}</MenuItem>
                          ))
                        }
                        <MenuItem onClick={() => { navigateToUrl(`/nft`) }} className={headerStyles.menuListItem}>NFTs</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <ListLink to="/blog" className={headerStyles.link} activeClassName={headerStyles.active} listClassName={headerStyles.linkContainer}>
              BLOG
            </ListLink>
            <ListLink to="/contact/" className={headerStyles.link} activeClassName={headerStyles.active} listClassName={headerStyles.linkContainer}>
              CONTACT US
            </ListLink>
          </ul>
        )}
        {!isLargeScreen && (
          <>
            <Link
              to="/"
              style={{ textShadow: `none`, backgroundImage: `none`, display: "flex", alignItems: "center", height: "100%" }}
              className={clsx(headerStyles.link, headerStyles.logo)}
            >
              <GatsbyImage
                image={image.logoSmall.childImageSharp.gatsbyImageData}
                style={{ overflow: "visible" }}
                imgStyle={{ objectFit: "contain" }} />
            </Link>
            <div className={headerStyles.hamburger__container}>
              <button
                onClick={() => {
                  setIsDrawerOpen((openState) => !openState);
                }}
                className={clsx(headerStyles.hamburger, headerStyles.hamburger__arrow, {
                  [headerStyles.is__active]: isDrawerOpen,
                })}
              >
                <span className={headerStyles.hamburger__box}>
                  <span className={headerStyles.hamburger__inner}></span>
                </span>
              </button>
            </div>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
            >
              <ul style={{ listStyle: `none`, display: "flex", flexDirection: "column", margin: 0 }}>
                <ListLink
                  to="/about/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  ABOUT US
                </ListLink>
                <ListLink
                  to="/services/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  SERVICES
                </ListLink>
                <ListLink
                  to="/services/portfolio"
                  listClassName={clsx(headerStyles.drawerLink, headerStyles.drawerSubLink)}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  - PORTFOLIO
                </ListLink>
                <ListLink
                  to="/rentals/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  RENTALS
                </ListLink>
                {rentalCollections.map(collection => (
                  <ListLink
                    to={`/rentals/items#${collection.handle}`}
                    listClassName={clsx(headerStyles.drawerLink, headerStyles.drawerSubLink)}
                    className={headerStyles.link}
                    activeClassName={headerStyles.active}
                    key={collection.handle}
                  >
                    - {collection.title}
                  </ListLink>
                ))}
                <ListLink
                  to="/shop/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  SHOP
                </ListLink>
                <ListLink
                  to="/shop/products"
                  listClassName={clsx(headerStyles.drawerLink, headerStyles.drawerSubLink)}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  - CANDLES
                </ListLink>
                <ListLink
                  to="/nft"
                  listClassName={clsx(headerStyles.drawerLink, headerStyles.drawerSubLink)}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  - NFTs
                </ListLink>
                <ListLink
                  to="/blog"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  BLOG
                </ListLink>
                <ListLink
                  to="/contact/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  CONTACT US
                </ListLink>
              </ul>
            </Drawer>
          </>
        )}
      </header>
      {props.children}
      <ShoppingCartDrawer open={isOpen} onClose={() => setIsOpen(false)} />
      <WishlistDrawer open={wishlistIsOpen} onClose={() => setWishlistIsOpen(false)} />
    </div>
  );
}

const FREE_SHIPPING_PRICE = 150;

const WrappedLinearProgress = withStyles({
  root: {
    backgroundColor: 'rgba(243, 200, 121, 0.451)',
    height: '10px',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'rgb(243, 200, 121)'
    }
  },
})(LinearProgress);

function ShoppingCartDrawer(props) {
  const { open, onClose } = props;
  const {
    checkout,
    removeLineItem,
    setIsOpen,
    addNote
  } = useContext(StoreContext);
  const [giftNote, setGiftNote] = useState(checkout && checkout.note ? checkout.note : "");
  
  const subtotal = checkout.totalPrice ? parseFloat(checkout.totalPrice.amount) : 0;
  let percentToFreeShipping = (subtotal / FREE_SHIPPING_PRICE) * 100;
  if (percentToFreeShipping > 100) {
    percentToFreeShipping = 100;
  }

  // check for free shipping override
  if (checkout.lineItems.find(item => item.variant.product.handle === 'meet-cute-scent-kit') && 
      checkout.lineItems.length === 1) {
    percentToFreeShipping = 100;
  }

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className={headerStyles.shoppingCartHeader}>
        <h1 className={headerStyles.shoppingCartHeaderTitle}>Shopping Cart</h1>
        <button
          className={headerStyles.shoppingCartHeaderClose}
          onClick={onClose}
        ><AiOutlineClose /></button>
      </div>
      <Box display="flex" flexDirection="column" alignItems="center" marginBottom="12px">
        <Box width="100%" className={headerStyles.shippingPercentLabelContainer}>
          <span className={headerStyles.shippingPercentLabel}>{percentToFreeShipping === 100 ? 'Congrats, you get free shipping!' : `${getPrice(FREE_SHIPPING_PRICE - subtotal)} to go for free shipping!`}</span>
        </Box>
        <Box width="70%" className={headerStyles.shippingPercentProgressContainer}>
          <WrappedLinearProgress variant="determinate" value={percentToFreeShipping} />
        </Box>
      </Box>
      <div className={headerStyles.lineItemContainer}>
        {checkout.lineItems && checkout.lineItems.length > 0 && checkout.lineItems.map(
          item => (
            <ShoppingCartLineItem 
              key={item.id} 
              onRemove={() => {
                removeLineItem(checkout.id, item.id);
              }} 
              closeShoppingCart={() => { setIsOpen(false); } }
              {...item} 
            />
          )
        )}
        {checkout.lineItems && checkout.lineItems.length === 0 && (
          <div className={headerStyles.noItemsInCart}>No items in cart.</div>
        )}
      </div>
      <div className={headerStyles.giftNote}>
        <textarea
          name="giftNote"
          type="textarea"
          value={giftNote}
          rows={4}
          placeholder="Enter optional gift message"
          onChange={e => {
            setGiftNote(e.target.value);
          }}
          onBlur={() => {
            addNote(checkout.id, giftNote);
          }}
          className={headerStyles.giftNoteInput}
        />
      </div>
      <div className={headerStyles.subTotal}>{`Subtotal `}<b>{getPrice(subtotal)}</b></div>
      <div className={headerStyles.checkoutButtonContainer}>
        {checkout.lineItems && checkout.lineItems.length > 0 && <a href={checkout.webUrl} className={headerStyles.checkoutButton}>Checkout</a>}
      </div>
    </Drawer>
  )
}

function ShoppingCartLineItem(props) {
  const { variant, title, quantity, onRemove, closeShoppingCart } = props;
  
  return (
    <div className={headerStyles.lineItem}>
      <div onClick={() => { navigate(`/shop/${variant.product.handle}`); closeShoppingCart(); }} className={headerStyles.lineItemLink}>
        <img className={headerStyles.lineItemPicture} src={variant.image.src} alt={`image for product ${title}`} />
        <div className={headerStyles.lineItemInfoContainer}>
          <div className={headerStyles.lineItemTitle}>{`${title}${quantity > 1 ? ` (x${quantity})` : ''}`}</div>
          <div className={headerStyles.lineItemPrice}>{getPrice(variant.price.amount)}</div>
        </div>
      </div>
      <button
        className={headerStyles.removeLineItem}
        onClick={onRemove}
      ><AiOutlineClose /></button>
    </div>
  )
}


function WishlistDrawer(props) {
  const { open, onClose } = props;
  const {
    wishlist,
    removeItemFromWishlist,
    setIsOpen
  } = useContext(WishlistContext);

  const subtotal = wishlist.lineItems.reduce((sum, curr) => sum + parseFloat(curr.variants[0].price.amount), 0);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className={headerStyles.shoppingCartHeader}>
        <h1 className={headerStyles.shoppingCartHeaderTitle}>Wishlist</h1>
        <button
          className={headerStyles.shoppingCartHeaderClose}
          onClick={onClose}
        ><AiOutlineClose /></button>
      </div>
      <div className={headerStyles.lineItemContainer}>
        {wishlist.lineItems && wishlist.lineItems.length > 0 && wishlist.lineItems.map(
          item => (
            <WishListLineItem 
              key={item.id} 
              onRemove={() => {
                removeItemFromWishlist(item);
              }} 
              closeWishList={() => { setIsOpen(false); } }
              {...item} 
            />
          )
        )}
        {wishlist.lineItems && wishlist.lineItems.length === 0 && (
          <div className={headerStyles.noItemsInCart}>No items in wishlist.</div>
        )}
      </div>
      {/* <div className={headerStyles.subTotal}>{`Subtotal `}<b>{getPrice(subtotal)}</b></div> */}
      <div className={headerStyles.checkoutButtonContainer}>
        {wishlist.lineItems && wishlist.lineItems.length > 0 && <Link href={"/contact?wishlist=true"} className={headerStyles.checkoutButton}>CONTACT US TO RESERVE</Link>}
      </div>
    </Drawer>
  )
}

function WishListLineItem(props) {
  const { variants, title, handle, onRemove, closeWishList, images, totalInventory } = props;
  
  return (
    <div className={headerStyles.lineItem}>
      <div onClick={() => { navigate(`/rentals/items/${handle}`); closeWishList(); }} className={headerStyles.lineItemLink}>
        <GatsbyImage className={clsx(headerStyles.lineItemPicture, headerStyles.wishlistPic)} image={images[0].gatsbyImageData} alt={`image for item ${title}`} />
        <div className={headerStyles.lineItemInfoContainer}>
          <div className={headerStyles.lineItemTitle}>{`${title}`}</div>
          <div className={headerStyles.lineItemPrice}>{`${getPrice(variants[0].price)} ${totalInventory > 1 ? "per item" : ""} `}</div>
        </div>
      </div>
      <button
        className={headerStyles.removeLineItem}
        onClick={onRemove}
      ><AiOutlineClose /></button>
    </div>
  )
}