import React from "react";
import * as styles from "./footer.module.css";
import { Link, useStaticQuery, graphql } from "gatsby";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import clsx from 'clsx';
import { GatsbyImage } from "gatsby-plugin-image";

const imageQuery = graphql`{
  logo: file(relativePath: {eq: "rainbow_white_footer.png"}) {
    childImageSharp {
      gatsbyImageData(height: 80, layout: FIXED)
    }
  }
}
`;

export default function Footer(props) {
  const images = useStaticQuery(imageQuery);

  return (
    <div style={{ width: "100%", float: "left", position: "relative", top: "120px", zIndex: 3 }}>
      <footer className={styles.footerContainer}>
        <div className={clsx(styles.column, styles.rainbowImageContainer)}>
          <Link
            to="/"
            style={{ textShadow: `none`, backgroundImage: `none`, display: "inline-block", alignItems: "center", height: "auto" }}
            className={clsx(styles.link, styles.logo)}
          >
            <GatsbyImage
              image={images.logo.childImageSharp.gatsbyImageData}
              style={{ overflow: "visible" }}
              imgStyle={{ objectFit: "contain" }} />
          </Link>
        </div>
        <div className={styles.column}>
          <Link
            to="/about/"
            className={styles.link}
            activeClassName={styles.active}
          >
            About Us
          </Link>
          <Link
            to="/services/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Services
          </Link>
          <Link
            to="/rentals/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Rentals
          </Link>
        </div>
        <div className={styles.column}>
          <Link
            to="/shop/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Shop
          </Link>
          <Link
            to="/blog/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Blog
          </Link>
          <Link
            to="/contact/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Contact Us
          </Link>
        </div>
        <div className={styles.column}>
          <Link
            to="/privacypolicy/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Privacy Policy
          </Link>
          <Link
            to="/termsandconditions/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Terms and Conditions
          </Link>
          <Link
            to="/shippingreturns/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Shipping and Returns
          </Link>
        </div>
      </footer>
    </div>
  );
}
