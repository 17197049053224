
export const getPrice = price =>
    Intl.NumberFormat(undefined, {
    currency: 'USD',
    minimumFractionDigits: 2,
    style: 'currency',
    }).format(parseFloat(price ? price : 0));

export const getUniqueCollections = (collections, handlesToShow = null) => {
    const collectionKeys = {};
    const retCollections = [];
    collections.forEach(collection => { 
        if (!collectionKeys[collection.handle] && (!handlesToShow || handlesToShow.find(toShow => toShow === collection.handle))) {
            retCollections.push(collection);
            collectionKeys[collection.handle] = collection;
        }
     });

     return retCollections;
}