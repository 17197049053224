import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../provider/StoreProvider';

export default function useClientServerWorkaround() {
  const [isClient, setIsClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffect(() => {
    setIsClient(true);
  }, []);

  return key;
}

export function useUpdateOpacityOnScrollIntoView(ref, offset) {
  useEffect(() => {
    let listener = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        if ((top + offset) >= 0 && (top - offset) <= window.innerHeight) {
          ref.current.style.opacity = 1;
        }
      }
    }

    //invoke immediately in case it is already in view
    listener();
    window.addEventListener("scroll", listener);

    return () => {
      if (listener) {
        window.removeEventListener("scroll", listener);
      }
    }
  }, [ref, ref.current, offset]);
}

export function useShopifyTags(allShopifyProducts) {
  const [tags, setTags] = useState([]);

  useEffect(() => {  
      setTags(allShopifyProducts.nodes ? 
        allShopifyProducts.nodes.reduce((accumTags, currentProduct) => {
              if (currentProduct.tags) {
                  const newTags = currentProduct.tags.filter(tag => !accumTags.includes(tag));
                  return accumTags.concat(newTags);
              }
              return accumTags;
          }, []) 
              : [])
  }, [allShopifyProducts.nodes, setTags]);

  return tags;
}

export function useShopifyProductIsSold(shopifyProductHandle) {
  const {
    getProductInfoByHandle
  } = useContext(StoreContext);

  const [isSold, setIsSold] = useState(false);
  useEffect(() => {
    getProductInfoByHandle(shopifyProductHandle)
      .then(product => {
        if (!product.availableForSale) {
          setIsSold(true);
        }
      });
  }, [shopifyProductHandle, getProductInfoByHandle]);

  return isSold;
}


export function useTopMostDiv(tags) {
  const [topmostIndex, setTopmostIndex] = useState(0);

  useEffect(() => {
      let listener = () => {
          const getTopOfElement = (id) => {
              const elem = document.getElementById(id);
              if (elem) {
                  return elem.getBoundingClientRect().top;
              }
              return -1;
          }


          setTopmostIndex(current => {
              let minTopValue = 999;
              return tags.reduce((accum, id, index) => {
                  const topOfElem = getTopOfElement(id);
                  if (topOfElem < minTopValue && topOfElem > 0) {
                      minTopValue = topOfElem;
                      return index;
                  }

                  return accum;
              }, current);
          });
      }

      window.addEventListener("scroll", listener);

      return () => {
          if (listener) {
              window.removeEventListener("scroll", listener);
          }
      }
  }, [tags]);

  return topmostIndex;
}