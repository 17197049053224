import React from "react";
import * as styles from "./bodyWrapper.module.css";

export default function BodyWrapper(props) {
  const { children, backgroundColor } = props;

  const styleOverride = {};
  if (backgroundColor) {
    styleOverride.backgroundColor = backgroundColor;
  }

  return <div className={styles.bodyWrapper} style={styleOverride}>{children}</div>;
}
