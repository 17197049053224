import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import SEO from "../components/seo";
import BodyWrapper from "./bodyWrapper";
import StoreProvider from "../provider/StoreProvider";

export default function GlobalLayout({ children, bodyBackgroundColor }) {
  return (
    <div style={{ height: "100%" }}>
      {/* <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap" rel="stylesheet" />
        <script src="http://sdks.shopifycdn.com/buy-button/2.1.7/buybutton.js"></script>
      </Helmet> */}
      <div style={{ height: "100%" }}>
        <SEO />
        <Header />
        <BodyWrapper backgroundColor={bodyBackgroundColor}>
          {children}
        </BodyWrapper>
        <Footer />
      </div>
    </div>
  );
}
